<template>
    <div class="container scroll-div">
        <div class="row">
            <div class="col-md-10 px-0">
                <p class="mb-0">
                    It will ensure the trigger isn't triggered again for the triggering person.
                </p>
            </div>
            <div class="col-md-2 text-md-right d-flex align-items-center">
                <i class="pi pi-trash" style="font-size: 1.5rem"></i>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    setup () {
        

        return {}
    }
}
</script>

<style scoped>

</style>